body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fondo {
  background-image: url('./resources/main.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

@media (max-width: 767px) {
  .fondo {
    height: 70vh;
  }
}

@media (min-width: 768px) {
  .fondo {
    height: 100vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}


.fondo-foto-principal{
  background: rgb(196,193,182);
  background: linear-gradient(150deg, rgba(196,193,182,1) 49%, rgba(167,145,119,1) 75%);
}

/*
.fondo2{
  background-image: url('./resources/rombo-flores.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
}
*/

.fondo-rama-olivo{
  background-size: cover;
  
}

.flipped-image {
  transform: scaleY(-1);
}

.fuente{
  font-family: 'Ephesis', cursive;
  font-size: 60px;
  color: #ffffff;
}

.contador-fecha{
  font-family: 'Passions Conflict', cursive;
  font-size: 100px;
  color: #24836b;
}

.fuente-black{
  font-family: 'Passions Conflict', cursive;
  font-size: 100px;
  color: #000000;
}
.fuente-black-big{
  font-family: 'Passions Conflict', cursive;
  font-size: 500px;
  color: #000000;
}

.color-dorado{
  color: #bb9264;
}

.mensaje-invitado{
  font-family: 'Ephesis', cursive;
  color: #24836b;
}

.info-boda-color{
  color: #24836b;
}

.custom-size{
  font-size: 4vw;
}

.fecha-top-color{
  color: #c19562 !important
}

.info-boda-title{
  font-family: 'Passions Conflict', cursive;
  color:#20563b;
  font-size: 90px;
}

.color-marron-oscuro{
  font-family: 'Passions Conflict', cursive;
  font-size: 100px;
  color:#796c66;
}
.temporizador{
  margin-bottom: "-20px";
}


.modal-backdrop.show {
  width: unset !important;
}

@media only screen and (max-width: 391px) {
  .fuente{
    font-size: 80px; /* Reducir tamaño de fuente */
    color: #ffffff;
  }
  
  .custom-margin-top{
    margin-top: 30px;
    margin-bottom: -70px;
  }
}

@media only screen and (max-width: 574px) {
  .custom-margin-xs{
    margin-top: 50px;
  }
  
}


.info-boda-subtitle {
  font-size: 1.5rem;
}

.info-boda-text {
  color:#24836b;
  font-size: 20px;
}

.info-boda-text-big {
  color:#24836b;
  font-size: 35px;
}

.custom-margin{
  margin-top: 70px ;
  margin-bottom: 50px ;
}


.fuente-programacion{
  font-size: 40px;
  color:#4e2d30;
}

.fuente-programacion-xs{
  font-size: 30px;
  color:#4e2d30;
}
.custom-color-dorado{
  color:#876906;
}

.border-custom {
  border-top: 2px solid #20563b;
  border-bottom: 2px solid #20563b;
}

.full-border-custom {
  border: 2px solid #20563b;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}


.custom-color-mensaje-info-menu{
  color:#876906;
}

.letra-form{
  font-family: 'Caveat', cursive;
  font-size: 25px;
}

.letra-from-sub{
  font-family: 'Public Sans', sans-serif;
}

.letra-form-sub2{
  font-family: 'Public Sans', sans-serif;
}

.custom-size {
  font-size: 50px;
}

@media (max-width: 523px) {
  .custom-size {
    font-size: 40px;
  }
}

@media (max-width: 426px) {
  .custom-size {
    font-size: 30px;
  }
}


.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.letra-from-sub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 5rem; /* o cualquier altura deseada */
  justify-content: center;
}

.font-main-text{
  font-family: 'Ephesis', cursive;
  font-size: 40px; 
}

.font-text{
  font-family: 'Ephesis', cursive;
  font-size: 40px; 
}

.font-title{
  font-family: 'Ephesis', cursive;
  font-size: 75px; 
}